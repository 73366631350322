import { ethers } from 'ethers'
import { useDispatch,useSelector } from "react-redux";
import { useEffect } from "react";
import contract from '@/abi/index'
import { objKeyObjectType } from "@/interface";
import { clearContracts, newContracts } from "@/redux/action";
// new出合约，放在redux里面
const useNewContract = () => {
    // signer
    const { signer } = useSelector((store) => (store as any).wallet);
    const contractObj = useSelector((store) => (store as any).contract);
    const dispatch = useDispatch()
    useEffect(() => {
        let obj: objKeyObjectType = {}
        // 遍历出合约
        signer && !Object.keys(contractObj).length && Object.keys(contract).forEach((key) => {
            obj[key] = new ethers.Contract(contract[key].address, contract[key].abi, signer);
        })
        // 遍历出的对象合约有的话，存入redux，没有的话不管
        Object.keys(obj).length && dispatch(newContracts(obj))
        // signer为空清除合约
        !signer && Object.keys(contractObj).length && dispatch(clearContracts()) && console.log('signer为空清除合约');
    }, [contractObj, dispatch, signer])
}
export default useNewContract