import { message } from "antd";

// 数据脱敏
export const mobileHidden = (
  value: string,
  start: number = 10,
  end: number = 4,
  d: number = 3
) => {
  const n = start - 1 + d;
  if (value) {
    const valueArray = value.split("");
    for (let i = start; i < valueArray.length - end; i++) {
      valueArray[i] = ".";
      if (i > n) {
        valueArray[i] = "";
      }
    }
    return valueArray.join("");
  }
  return '空地址'
};
// 复制
export const handleCopyClick = (data: string) => {
  try{
    const textField = document.createElement('textarea');
    textField.innerText = data;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand('copy');
    textField.remove();
    message.success("复制成功");

  }catch(e){
    message.error("复制失败");
  }
};
