import style from './index.module.scss'
import logo from '@/image/logo.png'
import language from '@/image/language.png'
import connected from '@/image/connected.png'
import copy from '@/image/copy.png'
import close from '@/image/close.png'
import fibo from '@/image/fibo.png'
import { Button, Dropdown, MenuProps } from 'antd'
import { useEffect, useState } from 'react'
import useConnectedWallet from '@/hooks/useConnectedWallet'
import { Modal } from 'antd';
import { useSelector } from 'react-redux'
import { addressConvert } from '@/utils/fbAddress'
import { handleCopyClick, mobileHidden } from '@/utils/mobileHidden'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

// 语言选择的内容
const items: MenuProps['items'] = [
    {
        key: 'cn',
        label: <div className={style.header_connect_dropdown_item}>中文</div>,
    },
    {
        key: 'en',
        label: <div className={style.header_connect_dropdown_item}>English</div>,
    },
];
const MyHeader = () => {
    const { t, i18n } = useTranslation();

    const navigate = useNavigate()
    // 语言
    const [languages, setLanguages] = useState('cn')
    const changeLanguages: MenuProps['onClick'] = ({ key }) => {
        i18n.changeLanguage(key);
        setLanguages(key)
    }
    // 连接钱包
    const [wallet, setWallet] = useState<undefined | string>(undefined)
    useConnectedWallet(wallet)
    // 获取钱包地址
    const { address } = useSelector(
        (store) => (store as any).wallet
    );
    // 连接钱包后点击出现的弹窗
    const [isModalOpen, setIsModalOpen] = useState(false);
    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };

    // 监听address，消失后清除wallet
    useEffect(() => {
        setWallet(undefined)
    }, [address])
    return (
        <div className={style.header}>
            {/* 左边 */}
            <div className={style.header_logo}>
                {/* logo图 */}
                <img onClick={() => { navigate('/') }} src={logo} alt="" />
                {/* 文字 */}
                <span>FUNDAO</span>
            </div>
            {/* 右边 */}
            <div className={style.header_connect}>
                {/* 语言切换 */}
                {false && <Dropdown
                    overlayClassName={style.header_connect_dropdown}
                    placement="bottomRight"
                    menu={{
                        items,
                        onClick: changeLanguages,
                        selectable: true,
                        defaultSelectedKeys: [languages],
                    }}
                >
                    <img onClick={(e) => e.preventDefault()} src={language} alt="" />
                </Dropdown>}
                {/* 连接钱包按钮 pc  移动*/}
                {!address && <Button onClick={() => setWallet('MetaMask')} className={style.header_connect_btn} type="primary">{t('ConnectedWallet')}</Button>}
                {/* 连接钱包后移动*/}
                {address && <div className={style.header_connect_connected} onClick={showModal}>
                    <img src={connected} alt="" />
                </div>}
                {/* 连接钱包后pc*/}
                {address && <div className={style.header_connect_connectedpc} onClick={() => { setWallet('break') }}>
                    <img src={fibo} alt="" /> {mobileHidden(addressConvert(address), 6, 4)}
                </div>}
                {/* 弹窗移动 */}
                <Modal className={style.header_connect_modal} open={isModalOpen} onCancel={handleCancel} footer={null} modalRender={() => (
                    <div className={style.header_connect_modal_context} >
                        <div className={style.header_connect_modal_context_title}>{t('myWallet')} <img onClick={handleCancel} src={close} alt="" /></div>
                        <h4>{t('walletAddress')}</h4>
                        <div className={style.header_connect_modal_context_address}>{mobileHidden(addressConvert(address), 10, 15)} <img onClick={() => { handleCopyClick(addressConvert(address)) }} src={copy} alt="" /></div>
                        <Button className={style.header_connect_modal_context_btn} type="primary" block onClick={() => {
                            setWallet('break')
                            setIsModalOpen(false)
                            navigate('/')

                        }}>
                            {t('ExitWallet')}
                        </Button>
                        <div className={style.header_connect_modal_context_fiboscan}><a href="https://scan.fibochain.org/">View on fiboscan</a></div>
                    </div>)}>
                </Modal>
            </div>
        </div>
    )
}

export default MyHeader