// 定义常量
// 连接钱包
export  const connectedWallet:string = 'connectedWallet';
// 断开钱包
export  const breakWallet:string = 'breakWallet';
// new出合约
export  const newContract:string = 'newContract';
// 清空合约
export  const clearContract:string = 'clearContract';

