import { walletNameList } from "@/walletName";
import { useCallback, useEffect, useState } from "react";
import useAddCustomChain from './useAddCustomChain'
import { useDispatch, useSelector } from "react-redux";
import { message } from 'antd';
import { chainIDArr, chainParams } from "@/chain";
import { breakWallets, connectedWallets } from "@/redux/action";

// 监听钱包的网络
const useListenerNetWork = () => {
  const dispatch = useDispatch();
  // 当前 切换链的方法
  const addCustomChain = useAddCustomChain()
  // 存放退出钱包的名字，用于关闭老钱包的监听网络事件
  const [oldWalletName, setOldWalletName] = useState('')
  // 判断有没有监听过网络
  const [switchs, setSwitchs] = useState(false)
  const { walletName, provider } = useSelector(
    (store) => (store as any).wallet
  );
  // 监听函数
  const listenerNetWork = useCallback(() => {
    if (switchs) return
    // 改变监听状态，，已连接状态
    setSwitchs(true)
    // 获取连接的钱包
    setOldWalletName(walletName)
    // eslint-disable-next-line no-eval
    eval(`window.${walletNameList[walletName]}`)
      ?.on("chainChanged", (newNetwork: any) => {
        const chainID = Number(newNetwork)
        // 查看切换的链是否是我们支持的链
        if (chainIDArr.indexOf(chainID) === -1) {
          message.warning(`您当前在Chain${chainID}下，无法为您提供服务`);
          // 如果是的话，我们需要重新连接钱包，更新provider，singer,在这之前我们需要把provider等清空，触发清除监听的函数
          dispatch(breakWallets());
          dispatch(connectedWallets(walletName) as any);
        }
      });
  }, [switchs, walletName, dispatch])
  // 删除监听函数
  const removeListener = useCallback(() => {
    // eslint-disable-next-line no-eval
    eval(`window.${walletNameList[oldWalletName]}`)?.removeAllListeners('chainChanged');
    // 改变钱包连接状态，已断开状态
    setSwitchs(false)
  }, [oldWalletName])
  // 进入检测用户在那个链上面，不在所需的链上，帮助他切换链
  const checkChainSupportF = async () => {
    // 获取网络信息
    const network = await provider.getNetwork();
    // 输出当前连接的链的信息
    // 将链 ID 转换为字符串，并去掉后缀 "n"
    const chainId = network.chainId.toString();
    const chainIdWithoutSuffix = chainId.endsWith("n")
      ? chainId.slice(0, -1)
      : chainId;
    if (chainIDArr.indexOf(chainIdWithoutSuffix) === -1) return addCustomChain(chainParams[12306])
  }
  useEffect(() => {
    // 当用户连接钱包后去 walletName 和  provider 是一定存在的，这样才可以去监听钱包的切换网络事件
    walletName && provider && listenerNetWork()
    // 如果 walletName 和  provider 为空代表用户断开钱包切换钱包或者没连接过钱包，要把之前监听网络的方法清除掉
    // eslint-disable-next-line no-eval
    !walletName && !provider && removeListener()
    // 检测用户在那条链上面，
    walletName && provider && checkChainSupportF()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [walletName, provider]);

};
export default useListenerNetWork;


