import { addCustomChainType } from "@/interface";
import { useSelector } from "react-redux";
// 切换链
const useAddCustomChain = () => {
    const { provider } = useSelector((store) => (store as any).wallet);
    return async (chainParams: addCustomChainType) => {
        try {
            const data = {
                ...chainParams,
                chainId: `0x${chainParams.chainId.toString(16)}`,
            };
            // 发送添加链的请求
            await provider.send("wallet_addEthereumChain", [data]);
        } catch (e) {
            console.log(e);
        }
    }
}
export default useAddCustomChain