import {Buffer} from 'buffer';
var fibo = require('bech32')
export const addressConvert = (address: string) => {
  try {
    if (address.substring(0, 2) === '0x') {
      // 如果是0x开头
      // 0x转fb
      let hexAddr = address
      hexAddr = hexAddr.slice(0, 2) === '0x' ? hexAddr.slice(2) : hexAddr
      const words = fibo.bech32.toWords(Buffer.from(hexAddr, 'hex'))
      const FBaddress = fibo.bech32.encode('fb', words)
      return FBaddress
    } else {
      // fb转0x
      const fbAddr = address
      const addrBuf = Buffer.from(fibo.bech32.fromWords(fibo.bech32.decode(fbAddr).words))
      const OXaddress =
        '0x' +
        Array.prototype.map
          .call(new Uint8Array(addrBuf), x => ('00' + x.toString(16)).slice(-2))
          .join('')
      return OXaddress
    }
  } catch (e) {
    return address
  }
}
export const validateAddress = (addr: any) => {
  try {
    const decodeAddress = fibo.bech32.decode(addr)
    if (decodeAddress.prefix === 'fb') {
      return true
    }

    return false
  } catch (err) {
    return false
  }
}
