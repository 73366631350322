import { lazy } from "react";

// 获取所有的pages页面
export const Home = lazy(() => import("@/pages/home")); // 首页
export const Creation = lazy(() => import("@/pages/creation")); // 创建
export const Daohome = lazy(() => import("@/pages/daohome")); // 提案首页
export const Govern = lazy(() => import("@/pages/govern")); // 治理
export const Member = lazy(() => import("@/pages/member")); // 成员
export const Setting = lazy(() => import("@/pages/setting")); // 设置
export const Createproposal = lazy(() => import("@/pages/createproposal")); // 创建提案
export const Proposalpage = lazy(() => import("@/pages/proposalpage")); // 提案
export const Setdao = lazy(() => import("@/pages/setdao")); // 提案