import { breakWallets, connectedWallets } from "@/redux/action";
import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux";
/*
useConnectedWallet 如果不传值，会去查看本地有缓存的值否。有的话自动连接，没有的话不做任何操作，
如果传值的话，只有断开，连接钱包两种操作，break 是断开，连接请填写钱包名称
*/
const useConnectedWallet = (walletSwitch: string | undefined) => {
    const dispatch = useDispatch();
    const { walletName } = useSelector(
        (store) => (store as any).wallet
    );
    // 钱包开关，其值是连接钱包的名称break是断开，其他事连接
    useEffect(() => {
        // 如果用户本地有钱包名称，且walletSwitch没有传入参数，代表是进入项目后，默认连接，
        // walletName是用户本地存的钱包名称
        setTimeout(()=>{
            if (walletSwitch === 'no') {
                return console.log('不执行操作')
            }
            else if (!walletSwitch && walletName) {
                console.log('默认连接钱包', walletName);
                dispatch(connectedWallets(walletName) as any)
                // walletSwitch 有值的情况下，只有两种情况，一个是断开钱包连接，一个是连接指定的钱包
            } else if (walletSwitch === 'break') {
                console.log(`断开钱包`);
                dispatch(breakWallets());
            } else if (walletSwitch) {
                console.log(`连接钱包${walletSwitch}`);
                dispatch(connectedWallets(walletSwitch) as any)
            }
        },0)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, walletSwitch])
}
export default useConnectedWallet