import { connectedWallet, breakWallet } from "./constants";
const reducerWallet = (state: any, action: any) => {
  switch (action.type) {
    case connectedWallet:
      return { ...state, ...action.payload };
    case breakWallet:
      return { provider: null, signer: null, address: "", walletName: null };
    default:
      return state;
  }
};

export default reducerWallet;
