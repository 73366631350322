import { newContract, clearContract } from "./constants";
const reducerContract = (state: any, action: any) => {
  switch (action.type) {
    case newContract:
      return { ...state, ...action.payload };
    case clearContract:
      return {};
    default:
      return state;
  }
};
export default reducerContract;
