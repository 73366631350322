import { Home, Creation, Daohome, Govern, Member, Setting, Createproposal, Proposalpage, Setdao } from './routes'

const routes = [
    {
        path: '/',
        element: <Home />,
        auth: true
    },
    {
        path: '/creation',
        element: <Creation />,
        auth: true
    },
    {
        path: '/daohome/:dao_address',
        element: <Daohome />,
        auth: true
    },
    {
        path: '/govern/:dao_address',
        element: <Govern />,
        auth: true
    },
    {
        path: '/member/:dao_address',
        element: <Member />,
        auth: true
    },
    {
        path: '/setting/:dao_address',
        element: <Setting />,
        auth: true
    },
    {
        path: '/createproposal/:dao_address',
        element: <Createproposal />,
        auth: true
    },
    {
        path: '/proposalpage/:dao_address/:id',
        element: <Proposalpage />,
        auth: true
    }, {
        path: '/setdao/:dao_address',
        element: <Setdao />,
        auth: true

    }

]
export default routes
