import { download, walletNameList, walletNameListShow } from "@/walletName";
import {
  connectedWallet,
  breakWallet,
  newContract,
  clearContract,
} from "./constants";
import store from "./index";
import { ethers } from "ethers";

// 连接钱包
export const connectedWallets = (walletName: string) => {
  return async (dispatch: any) => {
    try {
      // 判断有没有选中的钱包
      // eslint-disable-next-line no-eval
      if (eval(`window.${walletNameListShow[walletName]}`)) {
        // 有的话连接钱包
        const provider = new ethers.BrowserProvider(
          // eslint-disable-next-line no-eval
          eval(`window.${walletNameList[walletName]}`)
        );
        const signer = await provider.getSigner();
        const address = signer.address;
        // 获取到信息后，将本次连接的钱包名字存在本地
        // 判断缓存里面有没有连接过的钱包名称
        !store.getState().wallet.walletName &&
          localStorage.setItem("walletName", walletName);
        dispatch({
          type: connectedWallet,
          payload: { provider, signer, address, walletName },
        });
      } else {
        window.open(download[walletName]);
      }
    } catch (e) {
      console.log('e',e);
    }
  };
};
// 断开钱包
export const breakWallets = () => {
  localStorage.removeItem("walletName");
  return { type: breakWallet };
};
// new出合约
export const newContracts = (contracts: { [key: string]: object }) => {
  return { type: newContract, payload: {...contracts } };
};
// 清空合约
export const clearContracts = () => {
  return { type: clearContract };
};
