import { useRoutes } from 'react-router-dom';
import routes from '@/router';
import useListenerNetWork from './hooks/useListenerNetWork';
import useWatchWalletAddress from './hooks/useWatchWalletAddress';
import useNewContract from './hooks/useNewContract';
import MyHeader from '@/components/MyHeader'
// import "@/log/index";
import style from './App.module.scss'
import { Suspense } from 'react';

function App() {
  const routePages = useRoutes(routes)
  // 监听网络
  useListenerNetWork()
  // 监听地址
  useWatchWalletAddress()
  // new出合约
  useNewContract()
  return (
    <div className={style.App}>
      <Suspense fallback={<div>Loading...</div>}>
        <MyHeader />
        {routePages}
      </Suspense>
    </div>
  );
}

export default App;
