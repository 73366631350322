import { Interface, InterfaceAbi } from "ethers";

type objKeyObjectType = {
  [key: string]: { address: string; abi: Interface | InterfaceAbi };
};
const DAORegistry = require("./DAORegistry.json");
const DAOFactory = require("./DaoFactory.json");
let contract: objKeyObjectType = {
  // 注册合约
  DAORegistry: { abi: DAORegistry, address: "" },
  // 工厂合约
  DAOFactory: { abi: DAOFactory, address: "" },
};
if (process.env.NODE_ENV === "development") {
  //开发环境
  contract.DAORegistry.address = "0xf9Dd6AD16Eea9A28B946F14D28ac562422C5BCDA";
  contract.DAOFactory.address = "0x4EE297B59b28951D148F7eA391126a5deB89c58E";
  //   contract.DAORegistry.address = "0xeFA6d7133a65255b214b306153f79CCF2DbC3101"; //cs
  // contract.DAOFactory.address = "0xbFC39cD37704f21c80c244E54B1FF7f01bC1fB04";//cs
} else {
  //生产环境
  contract.DAORegistry.address = "0xf9Dd6AD16Eea9A28B946F14D28ac562422C5BCDA";
  contract.DAOFactory.address = "0x4EE297B59b28951D148F7eA391126a5deB89c58E";
}
export default contract;
