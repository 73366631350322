import { createStore, applyMiddleware } from "redux";
import reducerWallet from "./reducerWallet";
import thunk from "redux-thunk";
import reducerContract from "./reducerContract";

const initialState = {
  wallet: {
    provider: null,
    signer: null,
    address: "",
    walletName: localStorage.getItem("walletName") || "",
  },
  contract: {},
};
const rootReducer = (state = initialState, action: any) => {
  return {
    wallet: reducerWallet(state.wallet, action),
    contract: reducerContract(state.contract, action),
  };
};

// 利用store来保存状态（state）
const store = createStore(rootReducer, applyMiddleware(thunk));

export default store;
